import React,{Component} from 'react';
import 'font-awesome/css/font-awesome.min.css';
import big_logo from '../../Assets/Images/Fire_Recovery_USA_Logo_250x55-1.gif';
import echeck1_pic from '../../Assets/Images/echeck1.png';
import echeck2_pic from '../../Assets/Images/echeck2.png';
import axios from 'axios';
import Layout from '../Layout/Layout';
import { Link } from 'react-router-dom'
import * as constants from '../Global/Global';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {  Table } from 'react-bootstrap'
toast.configure();


class PaymentHelp extends Component{
    constructor(props) {
      super(props);
      this.state = {
          claimDetails :{
          claim_id: '',
          invoiceNumber: '',
          amount: '',
          amount_collected:0,
          rem_balance:0,
          validForm:false,
          invoiceValid: false,
          amountValid: false,
          alert:'' ,
          status:'',
          departmentName:'',
          creditCardFee:0,
          echeckFee:0,
          showHide:false,
      },
      formToken:{
        creditCard:'',
        eCheck:''
      }

    };
  }
   
 
  handleContinuePayment = ()=> { 
    this.setState({ showHide: false });   
    this.props.history.push({
      pathname: '/paymentform/'+this.state.claimDetails.invoice_type+'/'+this.state.claimDetails.invoiceNumber+'/e_check',          
      state: { payment_amount: this.props?.location?.state?.payment_amount }
    });
}

  /*
  This will life cycle method  handle the data fetch from DB
  */
  componentDidMount() {
    
    const invoice = this.props.match.params.id;
    const invoice_type  = this.props.match.params.invoice_type ? this.props.match.params.invoice_type :null;    
    //console.log(this.props);
    if ( typeof this.props?.location?.state?.payment_amount == 'undefined' ) {
      this.props.history.push({
        pathname: '/'        
      }); 
    }
    const axiosConfig = { params: {a: 0, payment_plan: this.state.payment_plan}};
    if ( typeof this.props?.location?.state?.payment_amount != 'undefined' ) {
      axiosConfig.params.a = Number(this.props?.location?.state?.payment_amount);
      axiosConfig.params.payment_plan = this.props?.location?.state?.payment_plan;
    }
    
    //console.log(process.env.PUBLIC_URL);

    if(invoice_type !== 'inspection') //For claim payments
    { 
              axios.get(constants.CLAIMDETAILS+'claim/'+invoice,axiosConfig)
              .then((response)=> {
              const res =response.data.result;
              if( res.length === 0)
              {
                alert( 'No claim found with this invoice number');
              }
              else if(res.claim.status =='Payment Received')
                  {
                   
                  toast.error('The specified invoice is already paid.'); 
                  this.props.history.push('/'); 

                  }
              else
              {
                  const invoiceData = res.claim ;
                  const currentClaimDetails = {...this.state.claimDetails};
                  currentClaimDetails.departmentName = invoiceData.name;
                  currentClaimDetails.creditCardFee =0;
                  currentClaimDetails.echeckFee = 0;
                  currentClaimDetails.amount = parseFloat(invoiceData.amount_invoiced).toFixed(2);
                  currentClaimDetails.amount_collected = parseFloat(invoiceData.amount_collected).toFixed(2);
                  let invoiceTotal = parseFloat(invoiceData.amount_invoiced);
                  let amountCollected = parseFloat(invoiceData.amount_collected);
                  let difference = 0;
                    // Check for NaN and null values
                    if (isNaN(invoiceTotal) || isNaN(amountCollected)) {                      
                      invoiceTotal = parseFloat(invoiceData.amount_invoiced).toFixed(2);
                      amountCollected = 0;
                    } 
                    difference = parseFloat(invoiceTotal) - parseFloat(amountCollected);                      
                   
                  currentClaimDetails.rem_balance =  difference;                 
                  currentClaimDetails.amountValid =true;
                  currentClaimDetails.alert = '';
                  currentClaimDetails.status = invoiceData.status;
                  currentClaimDetails.invoiceNumber = invoiceData.invoice_number;
                  currentClaimDetails.invoiceValid = true;
                  currentClaimDetails.validForm = true;
                  currentClaimDetails.claim_id = invoiceData.original_run_id;
                  currentClaimDetails.invoice_type ='claim';
                  currentClaimDetails.payment_amount = parseFloat(1 * this.props?.location?.state?.payment_amount ).toFixed(2);
                  currentClaimDetails.payment_plan = this.props?.location?.state?.payment_plan;  
                  currentClaimDetails.card_fee = parseFloat(invoiceData.card_fee) * 1;
                  currentClaimDetails.echeck_fee = parseFloat(invoiceData.echeck_fee) * 1;                  

                  this.setState({claimDetails:currentClaimDetails});

               }
              })
              .catch(function (error) {
                //console.log(error);
              });
             

    }
    else //For new inspection payments
    {


      axios.get(constants.INSPECTIONDETAILS+invoice,axiosConfig)
              .then((response)=> {
              const res =response.data.result;
              if( !res)
              {                
                toast.error('No Inspection/Claim invoice found for this invoice number.');  
              }
              else if(res.status =='Paid')
              {                
                 toast.error('The specified invoice is already paid.'); 
                 this.props.history.push('/'); 

              }
              else
              {
                 const invoiceData = res ;
                 //console.log(invoiceData.department.name);
                 const currentClaimDetails = {...this.state.claimDetails};
                  currentClaimDetails.departmentName = invoiceData.department.name;
                
                  currentClaimDetails.amount = parseFloat(invoiceData.invoice_total).toFixed(2);
                  currentClaimDetails.invoice_subtotal = parseFloat(invoiceData.invoice_subtotal).toFixed(2);
                  let invoiceTotal = parseFloat(invoiceData.invoice_total);
                  let amountCollected = parseFloat(invoiceData.amount_collected);
                  let difference = 0;
                    // Check for NaN and null values
                    if (isNaN(invoiceTotal) || isNaN(amountCollected)) {
                      invoiceTotal = parseFloat(invoiceData.invoice_total).toFixed(2);
                      amountCollected = 0;
                    } 
                    difference = parseFloat(invoiceTotal) - parseFloat(amountCollected);                      
                   
                  currentClaimDetails.rem_balance =  difference;
                  
                  currentClaimDetails.credit_surcharge = parseFloat(invoiceData.department.credit_surcharge);
                  currentClaimDetails.echeck_surcharge = parseFloat(invoiceData.department.echeck_surcharge);
                  currentClaimDetails.check_surcharge = parseFloat(invoiceData.department.check_surcharge);


                  currentClaimDetails.card_fee = parseFloat(invoiceData.card_fee) * 1;
                  currentClaimDetails.echeck_fee = parseFloat(invoiceData.echeck_fee) * 1;
                  currentClaimDetails.check_fee = parseFloat(invoiceData.check_fee) * 1;



                  currentClaimDetails.late_fee = parseFloat(invoiceData.late_fee) * 1;
                  currentClaimDetails.invoice_type ='inspection';
                  
                  
                  
                  currentClaimDetails.amountValid =true;
                  currentClaimDetails.alert = '';
                  currentClaimDetails.status = invoiceData.status;
                  currentClaimDetails.invoiceNumber = invoice;
                  currentClaimDetails.invoiceValid = true;
                  currentClaimDetails.validForm = true;
                  currentClaimDetails.claim_id = invoiceData.original_run_id;
                  currentClaimDetails.payment_amount = parseFloat(1 * this.props?.location?.state?.payment_amount ).toFixed(2);
                  this.setState({claimDetails:currentClaimDetails});

               }
              })
              .catch(function (error) {
                //console.log(error);
              });
            };


    }
  /*
  This will handle the JSX Rendering
  */
  render ()
  {
      return (

        <Layout>
        <div className="custom-container">      
                <div className="payment-wrap">
                    <div className="logo"><Link to="/"><img src={big_logo} alt=""/></Link></div>
                    <div className="pay-method">
                        <h1>E-check Payment Directions</h1>
                        <div className="pay-option payment">                        
                            <div className="content" style={{ padding:"5px"}}>
                               <p style={{ textAlign:"left"}}>To ensure a successful e-check transaction, accurate information must be provided on the payment page that will appear on the next screen.
                                 Here are some examples to assist you in identifying your routing and account numbers: </p>
                               <p style={{ textAlign:"center"}}><img src={echeck1_pic} alt="" style={{ maxWidth:"100%"}}  /></p>
                               <div style={{paddingBottom:"5px" }}>
                               <Table borderless  responsive size="sm" >
                               <tr>
                                  <td style={{textAlign:"left", width:"33%"}}>The Bank routing number is the 9 digits between the symbols as shown above.</td>
                                  <td style={{textAlign:"left", width:"2%"}}>&nbsp;</td>
                                  <td style={{textAlign:"left", width:"65%"}}>The checking account number is located to the left of the symbol shown above.</td>
                                </tr>
                                </Table>                               

                               <p style={{ textAlign:"left"}}>Some additional examples. As you can see, MICR lines are not always formatted the same.</p>

                                </div>

                               <p style={{ textAlign:"center"}}><img src={echeck2_pic} alt="" style={{ maxWidth:"100%"}}  /></p>
                               <p style={{ textAlign:"left"}}>Please identify the routing and account number format that applies in your situation and make sure to enter the numbers exactly as they appear on your check.</p>
                               <p style={{ textAlign:"left"}}>Thank you.</p>
                                <ul style={{TextAlign:"right"}}>
                                    <li>
                                        <button disabled={!this.state.claimDetails.invoiceNumber} type="button" onClick={this.handleContinuePayment} className="btn" >
                                            Continue To Payment
                                        </button> 
                                    </li>
                               </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </Layout>
      );

  };
}
export default PaymentHelp;
